import api from '@/services/Api';

class Reports {
  /**
   * Get a single Report
   * @returns {AxiosPromise<any>}
   */
  static get(id) {
    return api.get(`/reports/${id}`);
  }

  /**
   * Create a single Reports
   * @returns {AxiosPromise<any>}
   */
  static update(payload) {
    return api.put(`/reports/${payload.id}`, payload);
  }

  /**
   * Create a single Report Submission
   * @returns {AxiosPromise<any>}
   */
  static submit(payload) {
    return api.post(`/reports/${payload.id}/submit`, payload);
  }

  /**
   * Create a report file
   * @returns {AxiosPromise<any>}
   */
  static uploadFile(payload) {
    return api.post(`/reports/${payload.id}/files`, payload);
  }
}

export default Reports;
